* {
  box-sizing: border-box;
}

html {
  font-family: sans-serif;
  font-size: 15px;
}

#header {
  margin: 0 10rem;
  h1 {
    text-align: center;
    font-size: 2rem;
    color: red;
  }
}

body {
  margin: 0;
}

.container {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100vw;
  height: 80vh;
}

.stage {
  // width: 1020px;
  // height: 510px;
  background-image: url("/img/tile.png");
  position: relative;
}

.entity {
  position: absolute;
  width: 85px;
  height: 85px;

  &--pac {
    position: absolute;
    font-weight: bold;
    text-align: center;
    padding-top: 65px;
    user-select: none;

    &--mouthClosed {
      background-position-x: right;
    }
  }

  &--wall {
    background-image: url("/img/wall.png");
  }

  &--apple {
    background-image: url("/img/apple.png");
  }

  &--bomb {
    background-image: url("/img/bomb.png");
  }

  &--tomb {
    background-image: url("/img/tomb.png");
  }
}

.pacboy {
  &-active-light {
    background-image: url("/img/pacboy-active-light.png");
  }

  &-active-medium {
    background-image: url("/img/pacboy-active-medium.png");
  }

  &-active-dark {
    background-image: url("/img/pacboy-active-dark.png");
  }
}

.pacgirl {
  &-active-light {
    background-image: url("/img/pacgirl-active-light.png");
  }

  &-active-medium {
    background-image: url("/img/pacgirl-active-medium.png");
  }

  &-active-dark {
    background-image: url("/img/pacgirl-active-dark.png");
  }
}

.pacName {
  margin-top: -0.1rem;
}
